import { store } from '@/store/store';
import { cambioscontrasenasperdidas } from '@/shared/dtos/cambioscontrasenasperdidas';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'cambioscontrasenasperdidasModule',
    store,
    dynamic: true
})
class cambioscontrasenasperdidasModule extends VuexModule {
    public cambioscontrasenasperdidas: cambioscontrasenasperdidas = new cambioscontrasenasperdidas();

    @Action({ commit: 'onGetcambioscontrasenasperdidas' })
    public async getcambioscontrasenasperdidas(id: any) {
        return await ssmHttpService.get(API.cambioscontrasenasperdidas + '/' + id);
    }

    @Action
    public async guardarcambioscontrasenasperdidas(cambioscontrasenasperdidas: cambioscontrasenasperdidas) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.cambioscontrasenasperdidas, cambioscontrasenasperdidas.toJson());
    }

    @Action
    public async validarcambiodecontrasena(cambioscontrasenasperdidas: cambioscontrasenasperdidas) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.cambioscontrasenasperdidas + '/validate', cambioscontrasenasperdidas.toJson());
    }

    @Mutation
    public onGetcambioscontrasenasperdidas(res: cambioscontrasenasperdidas) {
        this.cambioscontrasenasperdidas = new cambioscontrasenasperdidas(res);
    }

}
export default getModule(cambioscontrasenasperdidasModule);